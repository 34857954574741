// extracted by mini-css-extract-plugin
export var linkDestacado = "Gracias-module--linkDestacado--31df2";
export var root = "Gracias-module--root--d32e9";
export var label = "Gracias-module--label--4f692";
export var formField = "Gracias-module--formField--8a93e";
export var input = "Gracias-module--input--c6a24";
export var textarea = "Gracias-module--textarea--7ee30";
export var section = "Gracias-module--section--09b91";
export var contactForm = "Gracias-module--contactForm--7aa17";
export var commentInput = "Gracias-module--commentInput--5d255";
export var customButton = "Gracias-module--customButton--ec32b";
export var marginTop = "Gracias-module--marginTop--229d9";
export var contactContainer = "Gracias-module--contactContainer--29568";