// extracted by mini-css-extract-plugin
export var linkDestacado = "Contact-module--linkDestacado--4b507";
export var root = "Contact-module--root--988f7";
export var label = "Contact-module--label--8b511";
export var formField = "Contact-module--formField--400ea";
export var input = "Contact-module--input--c4b89";
export var textarea = "Contact-module--textarea--f16ca";
export var section = "Contact-module--section--c8d4f";
export var contactForm = "Contact-module--contactForm--ff337";
export var commentInput = "Contact-module--commentInput--19313";
export var customButton = "Contact-module--customButton--266b9";
export var marginTop = "Contact-module--marginTop--33020";
export var contactContainer = "Contact-module--contactContainer--dc5ff";